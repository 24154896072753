import React from "react";

const CrawledCouponsListRemoved = () => {
  return (
    <div className="CrawledCouponsListRemoved">
      <span className="title">Cupons Removidos</span>
    </div>
  );
};

export default CrawledCouponsListRemoved;