import React from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";

import { ptBR } from "@mui/material/locale";
import AddCategory from "scenes/categories/add";
import Categories from "scenes/categories/list";
import UpdateCategory from "scenes/categories/update";
import Coupons from "scenes/coupons/list";
import AddCoupons from "scenes/coupons/add";
import CrawledCouponsList from "scenes/crawled_cupons/list/index";
import CrawledCouponsListNew from "scenes/crawled_cupons/new/index";
import CrawledCouponsListRemoved from "scenes/crawled_cupons/removed/index";
import Dashboard from "scenes/dashboard";
import Layout from "scenes/layout";
import Networks from "scenes/networks/list";
import UpdateNetwork from "scenes/networks/update/index";
import AddSpecial from "scenes/specials/add";
import Specials from "scenes/specials/list";
import UpdateSpecial from "scenes/specials/update";
import Stores from "scenes/stores/list";
import AddStore from "scenes/stores/add";
import AddNetwork from "scenes/networks/add/index";

import { Login } from "auth/login";
import { Logout } from "auth/logout";
import UpdateStore from "scenes/stores/update/index";

function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode), ptBR), [mode]);

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/crawled-coupon" element={<CrawledCouponsList />} />
              <Route path="/crawled-coupon/new" element={<CrawledCouponsListNew />} />
              <Route path="/crawled-coupon/removed" element={<CrawledCouponsListRemoved />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/categories" element={<Categories />} />
              <Route path="/categories/create" element={<AddCategory />} />
              <Route
                path="/categories/update/:id"
                element={<UpdateCategory />}
              />
              <Route path="/stores" element={<Stores />} />
              <Route path="/stores/create" element={<AddStore />} />
              <Route path="/stores/update/:id" element={<UpdateStore />} />
              <Route path="/coupons" element={<Coupons />} />
              <Route path="/add-coupons" element={<AddCoupons />} />
              <Route path="/specials" element={<Specials />} />
              <Route path="/specials/create" element={<AddSpecial />} />
              <Route path="/specials/update/:id" element={<UpdateSpecial />} />
              <Route path="/networks" element={<Networks />} />
              <Route path="/networks/create" element={<AddNetwork />} />
              <Route path="/networks/update/:id" element={<UpdateNetwork />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
