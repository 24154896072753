import React from "react";
import { Box, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "none",
  height: "80vh",
  [`& .MuiDataGrid-cell`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderBottom: "none",
  },
  [`& .MuiDataGrid-columnHeaders`]: {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.secondary[100],
    fontWeight: "bold",
    fontSize: "1.1rem",
    borderBottom: "none",
  },
  [`& .MuiDataGrid-footerContainer`]: {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.secondary[100],
    borderTop: "none",
  },
  [`& .MuiDataGrid-row--removed`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(255, 170, 170, 0.3)"
        : "rgba(255, 170, 170, 1)",
  },
  [`& .MuiDataGrid-row--edited`]: {
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(255, 254, 176, 0.3)"
        : "rgba(255, 254, 176, 1)",
  },
  [`& .MuiDataGrid-virtualScroller`]: {
    backgroundColor: "#FFFFFF",
  },
}));

const DataGridCustomTableClientSide = ({
  data,
  isLoading,
  columns,
  handleEditCommit,
  page,
  setPage,
  pageSize,
  setPageSize,
  rowCount,
}) => {
  return (
    <Box
      sx={{
      }}
    >
      <StyledDataGrid
        loading={isLoading || !data}
        getRowId={(row) => row.id}
        rows={data || []}
        columns={columns}
        processRowUpdate={handleEditCommit}
        experimentalFeatures={{ newEditingApi: true }}
        rowCount={rowCount}
        checkboxSelection
        pagination
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          setPage(0);
        }}
        rowsPerPageOptions={[10, 20, 50, 100]}
        paginationMode="client"
        headerHeight={250}
      />
    </Box>
  );
};

export default DataGridCustomTableClientSide;
