import { useMemo } from 'react';
import { format, parseISO, isValid } from 'date-fns';

const useFilteredData = (data, selectedSpider, filters) => {
  return useMemo(() => {
    if (!Array.isArray(data)) return [];

    return data.filter(item => {
      // Filtrar por Spider
      const matchesSpider = !selectedSpider || item.spider === selectedSpider;

      // Filtrar por outros filtros
      const matchesFilters = Object.entries(filters).every(([key, value]) => {
        if (value === "") return true;

        // Verificação de campos de data usando parseISO para formato ISO
        if (key === 'last_time_seen' || key === 'first_time_seen') {
          const itemDate = item[key] ? parseISO(item[key]) : null;
          const filterDate = value ? parseISO(value) : null;

          // Adicionar logs para depuração
          console.log("itemDate:", itemDate, "filterDate:", filterDate);

          // Verificar se ambas as datas são válidas antes de comparar
          if (!isValid(itemDate) || !isValid(filterDate)) {
            console.warn("Data inválida detectada:", itemDate, filterDate);
            return false;
          }

          return format(itemDate, 'yyyy-MM-dd') === format(filterDate, 'yyyy-MM-dd');
        }

        // Comparação para outros tipos de campos
        if (typeof item[key] === "boolean") {
          return item[key].toString().toLowerCase().startsWith(value.toLowerCase());
        }

        return item[key] && item[key].toString().toLowerCase().includes(value.toLowerCase());
      });

      return matchesSpider && matchesFilters;
    });
  }, [data, filters, selectedSpider]);
};

export default useFilteredData;
