import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, useTheme } from '@mui/material';
import { useGetCrawledCuponsQuery } from 'state/api';
import Header from 'components/Header';
import DataGridCustomTableClientSide from 'components/DataGridCustomTableClientSide';
import getColumns from './columns';
import AppBarWithFilters from '../components/AppBar';
import useFilteredData from '../components/FilteredData';
import { exportToCSV } from '../hooks/exportToCSV';

const CrawledCouponsList = ({ spider }) => {
  const theme = useTheme();
  const [page, setPage] = useState(2);
  const [pageSize, setPageSize] = useState(20);
  const [selectedSpider, setSelectedSpider] = useState("cuponomia");
  const { data = [], isLoading } = useGetCrawledCuponsQuery({}, { skip: false });
  const [spiderOptions, setSpiderOptions] = useState([]);
  const [isFilterOn, setIsFilterOn] = useState(true);
  const [filters, setFilters] = useState({ store_name: "", is_on_tr: "", is_new: "" });

  const handleFiltersChange = useCallback((event) => {
    const { name, value } = event.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  }, []);

  useEffect(() => {
    if (Array.isArray(data)) {
      const uniqueSpiders = Array.from(new Set(data.map(item => item.spider))).sort();
      setSpiderOptions(uniqueSpiders);
    }
  }, [data]);

  const filteredData = useFilteredData(data, selectedSpider, filters);

  const handleSpiderChange = (event) => {
    setSelectedSpider(event.target.value);
  };

  const handleExport = () => {
    exportToCSV(columns, filteredData);
  };

  const columns = getColumns(isFilterOn, filters, handleFiltersChange, spiderOptions, selectedSpider, handleSpiderChange);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem 1rem",
        height: "100vh",
      }}
    >
      <Header title="Todos Cupons do Crawler" subtitle="Tabela dos Cupons" />
      <AppBarWithFilters
        selectedSpider={selectedSpider}
        spiderOptions={spiderOptions}
        handleSpiderChange={handleSpiderChange}
        handleExport={handleExport}
      />
        <Box sx={{ flexGrow: 1 }}>
          <DataGridCustomTableClientSide
            data={filteredData}
            isLoading={isLoading}
            columns={columns}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            rowCount={filteredData.length}
          />
        </Box>
    </Box>
  );
};

export default CrawledCouponsList;
