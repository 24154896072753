import React from "react";

const CrawledCouponsListNew = () => {
  return (
    <div className="CrawledCouponsListNew">
      <span className="title">Cupons Novos</span>
    </div>
  );
};

export default CrawledCouponsListNew;