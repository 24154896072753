export const exportToCSV = (columns, data, fileName = 'data.csv') => {
  const csvHeader = columns.map((col) => `"${col.headerName}"`).join(',');
  const csvRows = data.map((row) =>
    columns.map((col) => {
      const cellValue = row[col.field];
      return `"${cellValue !== null && cellValue !== undefined ? cellValue.toString().replace(/"/g, '""') : ''}"`;
    }).join(',')
  );
  const csvContent = [csvHeader, ...csvRows].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
